* {
  box-sizing: border-box;
}

.body {
  display: flex;
  height: 100%;
}

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1;
  box-sizing: border-box;
  padding: 5px;
  height: 100%;
}
