.itemGroup {
  padding: 3px !important;
}

.mainItem {
  padding-left: 5px !important;
}

.listItem {
  padding-left: 15px;
  margin: 2px 0 !important;
}

.mainItem,
.listItem {
  .listIcon {
    min-width: 24px;
    margin-right: 5px;
  }
}
