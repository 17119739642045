.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-decoration: none;
  &:hover,
  &:active,
  &:visited {
    text-decoration: none;
    color: inherit;
  }

  .logo {
    &__icon {
      width: 40px;
      height: 40px;
    }
  }
}
